import './bootstrap';
import '../css/app.css';

import { createApp, DefineComponent, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout.vue';
// @ts-ignore
import vClickOutside from 'click-outside-vue3';
import { createPinia } from 'pinia';
import VueObserveVisibility from 'vue-observe-visibility';
import { vTooltip } from '@agentsoftware/spectre-ui';
import mixpanel from 'mixpanel-browser';
import VueMapboxTs from 'vue-mapbox-ts';
import * as Sentry from '@sentry/vue';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from 'ziggy-js';
import handleBrokenImage from './global';

const appName = import.meta.env.VITE_APP_NAME || 'Property Reports';

import.meta.glob([
  '../images/**',
]);

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: async (name) => {
    const page = await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue'));
    page.default.layout = page.default.layout || AppLayout;
    return page;
  },
  setup({
    el, App, props, plugin,
  }) {
    const app = createApp({ render: () => h(App, props) });

    const { user } = props.initialPage.props.auth;

    if (user) {
      Sentry.setUser({
        id: user.id as string,
        email: user.email,
      });
    }

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      environment: import.meta.env.MODE,
    });

    app.use(plugin)
      .use(vClickOutside)
      .use(VueObserveVisibility)
      .use(createPinia())
      .use(ZiggyVue)
      .use(VueMapboxTs)
      .directive('tooltip', vTooltip);

    app.config.globalProperties.$handleBrokenImage = handleBrokenImage;

    if (import.meta.env.PROD) {
      mixpanel.init(import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN, {
        persistence: 'localStorage',
      });
    }

    app.mount(el);
  },
  progress: {
    color: '#1233C9',
  },
});
